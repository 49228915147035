@import './components/Roulette/roulette.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

body {
  background-color: #f3f3f3;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
  justify-content: center;
}
