$item-count: 4;

.roulette {
}

.card {
  // background-color: #fff;
  padding: 2px;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(#000, 0.1);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.winner-card {
  position: relative;
  width: 105px;
  height: 105px;
  padding: 5px;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(#000, 0.1);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  // &:after {
  //   width: 25px;
  //   height: 25px;
  //   background: linear-gradient(white, #788eed);
  //   border: 5px solid #788eed;
  //   border-radius: 50%;
  //   box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  //   content: '';
  //   pointer-events: none;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  // }
}

.container {
  max-width: 800px;
  min-height: 510px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/// Mixin to place items on a circle
/// @author Hugo Giraudel
/// @author Ana Tudor
/// @param {Integer} $item-count - Number of items on the circle
/// @param {Length} $circle-size - Large circle size
/// @param {Length} $item-size - Single item size
@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: -($item-size / 2);

    $angle: (360 / $item-count);
    $rot: 45;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg) translate($circle-size / 2) rotate(($rot * 2deg) / $i);
      }

      $rot: $rot + $angle;
    }
  }
}

/**
 * make element center in parent
 * using absolute positioning
 * @param  {boolean} $vertical
 * @param  {boolean} $horizontal
 */
@mixin centered($vertical, $horizontal) {
  position: absolute;

  @if ($vertical and $horizontal) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  } @else if($horizontal) {
    left: 50%;
    transform: translateX(-50%);
  } @else if($vertical) {
    top: 50%;
    transform: translateY(-50%);
  }
}

.circleWrap {
  position: relative;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  // background: powderblue;
  border: 0px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circleInner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  // background: radial-gradient(rgb(240, 135, 88) 0%, rgb(229, 103, 47) 60%);
  z-index: 5;
}

.cta {
  @include centered(true, true);
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  white-space: nowrap;
  color: #000;
}

.skip {
  @include centered(true, true);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  white-space: nowrap;
  color: rgb(104, 104, 104);
}

.circle {
  // @include on-circle($item-count: $item-count, $circle-size: 330px, $item-size: 10rem);
  @include centered(true, true);

  li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
