.idea-card {
  // background-image: linear-gradient(#92a6e7, #5f76f3);
  border-radius: 50%;
  // box-shadow: rgb(0 0 0 / 19%) 0px 20px 18px -10px, rgb(0 0 0 / 33%) 0px 7px 10px -10px;
  display: block;
  overflow: hidden;
  transform: scale(1);
  width: 70px;
  height: 70px;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s !important;
}

.idea-image {
  inset: 0px;
  display: block;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  position: absolute;
  transition: opacity 500ms ease-in-out 0s;
  width: 100%;
  .jvByoS img:not(.hover-image) {
    z-index: 1;
  }
}

.idea-text {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  height: 100%;
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 20%;
}

.idea-image-hover {
  inset: 0px;
  display: block;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  position: absolute;
  transition: opacity 500ms ease-in-out 0s;
  width: 100%;
  z-index: 9999;
  .jvByoS img:not(.hover-image) {
    z-index: 1;
  }
}

.top-idea-image {
  inset: 0px;
  display: block;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  position: absolute;
  transition: opacity 500ms ease-in-out 0s;
  width: 100%;
  z-index: 10;
}

.idea-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}

.idea-hovered-image {
  inset: 0px;
  display: block;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  position: absolute;
  transition: opacity 500ms ease-in-out 0s;
  width: 100%;
  z-index: 1;
}
